/* ==========================================================================
   Imports
   ========================================================================== */

@import 'fonts';
@import 'variables';
@import 'mixins';

/* ==========================================================================
   Theming for Angular Material
   ========================================================================== */

/* ==========================================================================
   Default Styles
   ========================================================================== */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* ==========================================================================
   Typography
   ========================================================================== */

html,
body,
input,
select,
textarea {
}
//
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h2 {
  font-size: rem(20px);
}

h3 {
  font-size: rem(18px);
}

h4 {
  font-size: rem(16px);
}

h5 {
  font-size: rem(14px);
}

h6 {
  font-size: rem(12px);
}

a {
  color: $blue-dark;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $red-medium;
  }
}

/* ==========================================================================
   Functional Classes
   ========================================================================== */

.slumber-hidden-mobile {
  @media only screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.slumber-cursor-pointer {
  cursor: pointer;
}
.slumber-background-red {
  background-color: $red-medium !important;
}
.slumber-background-blue {
  background-color: $blue-dark !important;
}
.slumber-background-black {
  background-color: black !important;
}
.slumber-color-red {
  color: $red-medium !important;
}
.slumber-color-white {
  color: white !important;
}
.slumber-color-blue {
  color: $blue-dark !important;
}
.slumber-color-light-grey {
  color: $grey-border !important;
}

.slumber-text-align-center {
  text-align: center;
}

.slumber-bolder {
  font-weight: bolder;
}

.slumber-justify-content-center {
  justify-content: center;
}

.slumber-centered {
  display: flex;
  margin: auto;
  height: 100%;
  width: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
}

.slumber-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slumber-add-container {
  display: flex;
  align-items: center;
}

/* ==========================================================================
   Material Overrides
   ========================================================================== */

.mat-mdc-form-field-hint-wrapper {
  padding-left: 0 !important;
}

.mat-mdc-form-field-hint {
  color: red !important;
}

.mat-mdc-radio-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mat-mdc-radio-button {
  .mdc-radio {
    --mdc-radio-state-layer-size: 25px;
  }
}

.mat-mdc-dialog-actions {
  padding-left: 24px !important;
  padding-bottom: 14px !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field {
  padding-left: 0 !important;
  background-color: transparent !important;
}

.mdc-button {
  letter-spacing: normal !important;
}
