/*
class func baseDarkBlueColor() -> UIColor {
        return UIColor(red: 0.00, green: 0.08, blue: 0.13, alpha: 1.0)
    }

    class func baseLightBlueColor() -> UIColor {
        return UIColor(red: 0.00, green: 0.12, blue: 0.20, alpha: 1.0)
    }

    class func baseBlackColor() -> UIColor {
        return UIColor(red: 1.00, green: 1.00, blue: 1.00, alpha: 1.0)
    }

    class func baseGreenColor() -> UIColor {
        return UIColor(red: 0.11, green: 0.63, blue: 0.60, alpha: 1.0)
    }

    class func baseLightGreenColor() -> UIColor {
        return UIColor(red: 0.10, green: 0.63, blue: 0.42, alpha: 1.0)
    }

    class func baseLightGrayColor() -> UIColor {
        return UIColor(red: 0.85, green: 0.85, blue: 0.85, alpha: 1.0)
    }

    class func slumberPink() -> UIColor {
        return UIColor(red: 0.95, green: 0.39, blue: 0.45, alpha: 1.0)
    }

    class func slumberDarkBackground() -> UIColor {
        return UIColor(red: 0.00, green: 0.08, blue: 0.13, alpha: 1.0)
    }

    class func slumberLightBlue() -> UIColor {
        return UIColor(red: 0.15, green: 0.52, blue: 0.65, alpha: 0.8)
    }

    class func slumberLightGreen() -> UIColor {
        return UIColor(red: 0.28, green: 0.62, blue: 0.61, alpha: 1.0)
    }

    class func slumberSettingsBackground() -> UIColor {
        return UIColor(red: 0.00, green: 0.11, blue: 0.17, alpha: 1.00)
    }

    //hex 14DDEA
    class func slumberBrightBlue() -> UIColor {
        return UIColor(red: 0.08, green: 0.87, blue: 0.92, alpha: 1.0)
    }

    //hex 3D3DEE
    class func slumberDarkBlue() -> UIColor {
        return UIColor(red: 0.24, green: 0.24, blue: 0.93, alpha: 1.0)
    }

    //hex D8B28B
    class func slumberGold() -> UIColor {
        return UIColor(red: 0.85, green: 0.70, blue: 0.55, alpha: 1.0)
    }

    //hex 0B2739
    class func slumberDarkBlueBackground() -> UIColor {
        return UIColor(red: 0.04, green: 0.15, blue: 0.22, alpha: 1.0)
    }

    //hex 051730
    class func slumberNightSky() -> UIColor {
        return UIColor(red: 0.02, green: 0.09, blue: 0.19, alpha: 1.00)
    }

    //hex 1BA098
    class func slumberAquaAction() -> UIColor {
        UIColor(red: 0.11, green: 0.63, blue: 0.60, alpha: 1.0)
    }

    class func slumberOnboardingButton() -> UIColor {
        UIColor(red: 0.1, green: 0.22, blue: 0.31, alpha: 1.0)
    }

    class func slumberOnboardingButtonBorder() -> UIColor {
        UIColor(red: 0.2, green: 0.56, blue: 0.57, alpha: 1.0)
    }
 */
@use '@angular/material' as mat;

/* ==========================================================================
   Variables - Colours
   ========================================================================== */

$grey-light: #f0f0f0;
$grey-medium: #d8d8d8;
$grey-dark: #393e41;
$grey-border: #b0b2b3;

$red-light: #fdf3f2;
$red-medium: #e11900;

$blue-light: #dae4fb;
$blue-dark: #1a2155;

$blue-bright: #98b8f3;

$green-medium: #9aeb90;
$green-task-background: #dcffd3;
$green-dark: #2bc546;

$yellow-medium: #ffe789;

$beige-light: #f6f2e8;

/* ==========================================================================
   Variables - Fonts
   ========================================================================== */

//$font-stack-regular: 'GT-Eesti-Text-Regular', sans-serif;
//$font-stack-light: 'GT-Eesti-Text-Light', sans-serif;
//$font-stack-medium: 'GT-Eesti-Text-Medium', sans-serif;

/* ==========================================================================
   Variables - Breakpoints - update app/breakPoints.ts also
   ========================================================================== */

$breakpoint-laptop: 1440px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-mobile: 740px;

/* ==========================================================================
   Variables - Misc
   ========================================================================== */

$header-height-desktop: 64px;
$header-height-mobile: 64px; // todo double check this

/* ==========================================================================
   Material Colour Map
   ========================================================================== */

$mat-primary: (
  50: #e4e4eb,
  100: #babccc,
  200: #8d90aa,
  300: #5f6488,
  400: #3c426f,
  500: #1a2155,
  600: #171d4e,
  700: #131844,
  800: #0f143b,
  900: #080b2a,
  A100: #6672ff,
  A200: #3343ff,
  A400: #0014ff,
  A700: #0012e6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

mat.$red-palette: (
  50: #fbe3e0,
  100: #f6bab3,
  200: #f08c80,
  300: #ea5e4d,
  400: #e63c26,
  500: #e11900,
  600: #dd1600,
  700: #d91200,
  800: #d50e00,
  900: #cd0800,
  A100: #fff5f5,
  A200: #ffc2c2,
  A400: #ff908f,
  A700: #ff7775,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);
