@import 'variables';

/* ==========================================================================
   Functions
   ========================================================================== */

@function rem($pixels) {
  @return $pixels / 16px * 1rem;
}

/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $grey-border;
    border-radius: 10px;
    border: 4px solid #ffffff;
    &:hover {
      background-color: darken($grey-border, 10%);
    }
  }
}

@mixin content-page {
  padding: 1em;
  display: block;
  :host-context(.slumber-env-dev) {
    background: $red-light;
  }
  :host-context(.slumber-env-staging) {
    background: $beige-light;
  }
  :host-context(.slumber-env-prod) {
    background: $blue-light;
  }
}

@mixin grid-gap {
  display: grid;
  grid-gap: 1em;
}

@mixin list-cell {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-bottom: $grey-light;
  background: white;
}

@mixin slumber-form {
  padding: 1em;
  background-color: white;
}
